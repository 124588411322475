<template>
  <nb-players-and-duration
    :booking="booking"
    :slot-data="slotData"
    :booking-type="bookingType"
    :selected-playground="selectedPlayground"
    @on:timetable-block-price:changed="$emit('on:timetable-block-price:changed')"
  />
</template>
<script>
import {DEFAULT_BOOKING_TYPE} from "@/classes/doinsport/BookingModel";

export default {
  components: {
    NbPlayersAndDuration: () => import("@form/booking/create-or-update/options/timetable-block-price/NbPlayersAndDuration")
  },
  props: {
    booking: {
      type: Object,
      default: this,
    },
    bookingType: {
      type: String,
      default: DEFAULT_BOOKING_TYPE,
    },
    slotData: {
      type: Object,
      default: null,
    },
    selectedPlayground: {
      type: Object,
      default: this,
    },
  },
}
</script>
